import { JitsiMeet } from "@mycure/vue-jitsi-meet";
import initSensor from "../../../modules/AmbientLightHandler";
import { mapGetters } from "vuex";

export default {
  name: "Meet",
  mounted() {
    initSensor(this.$route.name);
    // eslint-disable-next-line no-undef
    console.log(this.$refs.jitsiRef.getIframe());
  },
  components: {
    VueJitsiMeet: JitsiMeet,
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
    jitsiOptions() {
      return {
        roomName: "Kaffeeecke",
        noSSL: false,
        userInfo: {
          displayName: this.getUserInfo.name,
        },
        lang: "de",
        configOverwrite: {
          disableSelfView: true,
          disableSelfViewSettings: true,
          enableNoisyMicDetection: false,
          enableWelcomePage: false,
          hideDisplayName: true,
          disableProfile: true,
          prejoinConfig: {
            enabled: false,
            hideExtraJoinButtons: ["no-audio", "by-phone"],
          },
          toolbarButtons: [
            "camera",
            "fullscreen",
            "hangup",
            "highlight",
            "microphone",
            "select-background",
            "toggle-camera",
            "videoquality",
            "__end",
          ],
        },
        interfaceConfigOverwrite: {
          APP_NAME: "Kaffeeecke",
          DEFAULT_BACKGROUND: "#040404",
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
        },
        onload: this.onIFrameLoad,
      };
    },
  },
  methods: {
    // Setup events after the IFrame onload event
    onIFrameLoad() {
      this.$refs.jitsiRef.addEventListener(
        "participantJoined",
        this.onParticipantJoined
      );
      this.$refs.jitsiRef.addEventListener(
        "participantLeft",
        this.onParticipantLeft
      );
    },
    onParticipantJoined(e) {
      console.log("🤸🏼‍♂️");
      console.log(e);
    },
    onParticipantLeft(e) {
      console.log("👩🏼‍🎤");
      //this.$refs.jitsiContainer.getParticipantsInfo();
      JitsiMeet.getParticipantsInfo();
      //this.$refs.jitsiRef.executeCommand('getParticipantsInfo', 'The display name');
      console.log(e);
    },
  },
};
