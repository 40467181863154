<template>
  <div>
    <label>Office Passwort</label>
    <input type="password" v-model="userInfo.password"/>
    <label>Name</label>
    <input type="text" v-model="userInfo.name"/>
    <button @click="saveInfo">Save</button>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      userInfo: {
        name: "",
        password: "",
      }
    }
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  created() {
    this.userInfo = this.getUserInfo;
  },
  methods: {
    ...mapActions(["setInfos"]),
    saveInfo() {
      this.setInLocalStorage('password', this.userInfo.password)
      this.setInLocalStorage('name', this.userInfo.name)
      this.setInfos(this.userInfo)
    },
    setInLocalStorage(item, value) {
      localStorage.setItem(item, value);
    }
  },
};
</script>
<style lang="scss">
#meeting {
  height: 100vh;
  width: 100vw;
}
</style>
