import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const USER_INFO = {
  name: "",
  password: "",
};

const OFFICE_PASSWORD = "abcd1234";

export default new Vuex.Store({
  state: {
    userInfo: {
      ...USER_INFO,
    },
  },
  mutations: {
    SET_USER_INFO(state, infos) {
      state.userInfo = JSON.parse(JSON.stringify(infos));
    },
  },
  actions: {
    setInfos({ commit }, infos) {
      commit("SET_USER_INFO", {
        ...infos,
      });
    },
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
    checkOfficePassword: (state) => {
      if (
        state.userInfo.password &&
        state.userInfo.password === OFFICE_PASSWORD
      ) {
        return true;
      }
      return false;
    },
  },
  modules: {},
});
