import Vue from "vue";
import VueRouter from "vue-router";
import Meet from "@/views/Meet/Meet.vue";
import Home from "@/views/Home/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/meet",
    name: "Meet",
    component: Meet,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.checkOfficePassword) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
