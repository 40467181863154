export default function initSensor(routName) {
  // Feature detection
  if (window.AmbientLightSensor) {
    try {
      // eslint-disable-next-line no-undef
      const sensor = new AmbientLightSensor();

      const luxEL = document.getElementById("lux");
      if (luxEL) luxEL.innerHTML = sensor.illuminance;
      // Detect changes in the light
      sensor.onreading = () => {
        // Read the light levels in lux
        // < 50 is dark room
        if (sensor.illuminance < 50) {
          document.body.className = "darkLight";
        } else {
          document.body.className = "brightLight";
          if (routName !== "Meet") window.location.replace("/meet");
        }
      };

      // Has an error occured?
      sensor.onerror = (event) => console.log(event.error.message);
      sensor.start();
    } catch (err) {
      console.log(err.message);
    }
  } else {
    console.log("It looks like your browser doesnt support this feature");
  }
}
