<template>
  <div id="app">
    <div id="nav">
      <button @click="show = !show">Menu</button>
    </div>
    <Menu v-if="show"></Menu>
    <router-view />
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      show: false,
      userInfo: {
        name: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["setInfos"]),
  },
  computed: {
    ...mapGetters(["checkOfficePassword"]),
  },
  created() {
    this.userInfo.name = localStorage.getItem("name");
    this.userInfo.password = localStorage.getItem("password");

    this.setInfos(this.userInfo);

    if (this.checkOfficePassword) {
      console.log("is an office device");
    }
  },
};
</script>
<style lang="scss">
@import 'scss-reset/_reset.scss';
</style>
